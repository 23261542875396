@tailwind base;
@tailwind components;
@tailwind utilities;

body{

  background-color: black;
  
}

left{
  margin-left: -4px;
}

