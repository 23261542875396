.border-b2{

    border-bottom-width: 2px;
    color: black;
  }

  body {
    
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: black;
  }
  
  .title {
    font-size: small;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
    color: black;
  }
  
  h3 {
    padding-top: 0.25em;
    color: black;
  }
  
  .vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
      0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
    color: black;
  }
  
  .date {
    color: white;
  }
  
  #description {
    margin: 1.5em 0 2em 0;
    color: black;
  }
  
  .button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
  }
  
  .workButton {
    background-color: white;
  }
  
  .workButton:hover {
    background-color: black;
  }
  
  .schoolButton {
    background-color: white;
  }
  
  .schoolButton:hover {
    background-color: black;
  }
  
  @media only screen and (max-width: 1700px) {
    .vertical-timeline-element-date {
      display: block !important;
      float: none !important;
      color: rgb(44, 44, 44);
      margin-top: 1.5em;
    }
  }

  .shift{

    padding-bottom: 20;
  }

  .center{

    justify-content: center;
    align-items: center;
  }

  .custom-list-item::before {
    content: '\2022'; /* Unicode character for a bullet point (•) */
    color: black; /* Set the color of the bullet point */
    display: inline-block;
    width: 1em; /* Adjust the width of the space between the bullet point and the text */
    margin-left: 1em; /* Offset to align the bullet point properly */
    font-size: 1.5em;
  }


  .custom-list-item {
    margin-bottom: 8px; /* Adjust the spacing between items as needed */
  }

  .black22{

    color:black
  }

  .custom-margin {
    margin-left: 20px; /* Adjust the margin value as needed */
  }